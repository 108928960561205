.App-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 90vh;
  margin-top: 10vh;
}

@media screen and (max-width: 500px) {
  .App-container {
    margin-top: 2vh;
  }
  .App-container h1 {
    font-size: 1.3em;
  }
  .App-container h3 {
    font-size: 1em;
    margin-top: 8px;
    font-weight: normal;
  }
}

.App-title {
  text-align: center;
}

.App-generateButton {
  font-weight: bold;
  margin-top: 20px;
  background: #27557a;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 12px 18px;
  font-size: 16px;
  cursor: pointer;
}

.App-apiButton {
  margin-top: 18px;
  color: darkgray;
  font-weight: bold;
  text-decoration: none;
}

.App-wantCodeReviewContainer {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .App-wantCodeReviewContainer {
    position: absolute;
    bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 12px;
  }
}

.App-wantCodeReview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 40px;
  color: darkgray;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
  text-decoration: none;
}

.App-wantCodeReview:hover {
  opacity: 1;
}

.App-prlogo {
  background-image: url("pr-logo.svg");
  width: 117px;
  height: 30px;
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  .App-prlogo {
    width: 78px;
    height: 20px;
    margin-top: 6px;
  }
}

.Code-img {
  max-width: 98vw;
}

.Code-imgContainer {
  overflow-x: auto;
}

.Browser {
  max-width: 85vw;
  background: #e7eaed;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-top: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.Browser-titleBar {
  height: 1.25em;
}

.Browser-button {
  margin-left: 0.5rem;
  float: left;
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;
  opacity: 0.6;
}

.Browser-closeButton {
  background-color: red;
}

.Browser-minimizeButton {
  background-color: #ffbb0f;
}

.Browser-maximizeButton {
  background-color: #39c44e;
}

.Comment-container {
  background: white;
  display: flex;
  margin: 0.5em;
  padding: 0.5em;
  min-height: 80px;
}

.Comment-avatarContainer {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 3px;
}

.Comment-textContainer {
  max-width: 690px;
  padding: 0.5em 1.2em;
  display: flex;
  flex-direction: column;
}

.Comment-username {
  font-weight: bold;
}

.Comment-text {
  line-height: 1.5em;
  font-size: 1.2em;
  margin-top: 14px;
  background: white;
}

@media screen and (max-width: 500px) {
  .Comment-text {
    line-height: 1.2em;
    font-size: 1em;
    margin-top: 14px;
    background: white;
  }
}
